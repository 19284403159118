import React from 'react'

import Layout from '../components/layout'
import SEO from '../components/seo'
import Contact from '../components/contact'

const Test = () => (
  <Layout>
    <Contact />
  </Layout>
);

export default Test;