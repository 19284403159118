import React from 'react';
import LinkedinIcon from '../vendors/fontAwesome/svgs/linkedin-in.svg';
import GithubIcon from '../vendors/fontAwesome/svgs/github.svg';
import { graphql, useStaticQuery } from 'gatsby';

const Contact = () => {
  const data = useStaticQuery(graphql`
    query {
      site {
        siteMetadata{
          github
          linkedin
          email
        }
      }
    }
  `)

  return (
    <div id="contact">
    <section>
      <h1>Contact</h1>
      <p>Have a project? A question?</p>
      <p>Feel free to contact me via the below or drop me an <a href={"mailto:"+data.site.siteMetadata.email}>email</a>!</p>

      <div className="contact-btn-box">
        <a className="contact-a" href={data.site.siteMetadata.linkedin}>
          <div className="contact-btn-container button button--secondary">
            <div><LinkedinIcon className="icon--contact" /></div>
            <div className="contact-btn-text">lineileen</div>
          </div>
        </a>

        <a className="contact-a" href={data.site.siteMetadata.github}>
        <div className="contact-btn-container button button--secondary contact-btn-margin">
          <div><GithubIcon className="icon--contact"/></div>
          <div className="contact-btn-text">minteapixel</div>
        </div>
        </a>
    
    
      </div>
    </section>
    </div>
  )
}

export default Contact
